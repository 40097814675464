import { createContext, useState } from "react";
import { A4_HEIGHT_MM, A4_WIDTH_MM, ATTACHMENT, CHECKBOX, MANDATORY, RADIOBUTTON, TEXTBOX } from "../constants/GeneralConstants";
import Helper from "../util/helper";
export const FormContext = createContext(null);

export const FormContextProvider = ({ children }) => {
    const [form, setForm] = useState(null);
    const [formTransform, setFormTransform] = useState(null);
    const [document, setDocument] = useState(null);
    const [maxPageWithQuestions, setMaxPageWithQuestions] = useState(0);
    const isHTML = form?.Layout?.IsHTML;

    const checkDisabled = (comp, isAdvancedForm = false) => {
        if (comp.condition === null) {
            return false;
        } else {
            const conditionComponent = [];
            formTransform.map(groups => {
                groups.map(group => {
                    const target = group.components.filter(val => val.id === comp.condition.id)[0];
                    if (target) {
                        conditionComponent.push(target);
                    }
                });
            });
            let isDisabled = false;
            if (conditionComponent.length > 0) {
                if (conditionComponent[0].type === RADIOBUTTON) {
                    isDisabled = conditionComponent[0].selectedchoice !== comp.condition.result - 1;
                }
                else if (conditionComponent[0].type === TEXTBOX) {
                    if (comp.condition.result === 1) {
                        isDisabled = isAdvancedForm ? conditionComponent[0].response.text === '' : conditionComponent[0].response === '';
                    }
                    else {
                        isDisabled = isAdvancedForm ? conditionComponent[0].response.text !== '' : conditionComponent[0].response !== '';
                    }
                } else {
                    isDisabled = conditionComponent[0].response === false || conditionComponent[0].response === '' || conditionComponent[0].response === undefined;
                }
            }
            return isDisabled;
        }
    }

    const getComponentOriginalPosition = (componentId) => {
        let target;
        formTransform.forEach((section, sectionIndex) => {
            section.forEach((group, groupIndex) => {
                const componentIndex = group.components.findIndex((component) => component.id === componentId);
                
                if (componentIndex !== -1) target = {
                    sectionIndex,
                    groupIndex,
                    componentIndex,
                };
            });
        });

        return target;
    };

    const cleanForm = () => {
        const form = formTransform.map(groups => {
            groups.map(group => {
                group.components = group.components.map(comp => {
                    if (comp.type === RADIOBUTTON) {
                        comp.choices = comp.choices.map(choices => choices);
                        comp.selectedchoice = undefined;
                    }
                    if (comp.type === CHECKBOX) {
                        comp.response = undefined;
                    }
                    if (comp.type === TEXTBOX) {
                        comp.response = "";
                    }
                    if (comp.type === ATTACHMENT) {
                        comp.response.fileinbase64 = null;
                    }
                    return comp;
                });
                return group;
            });
            return groups;
        });
        return form;
    }

    const getScreenDimensions = () => {
        const desktopContainer = window.document.querySelector('#desktop-container');
        let imageContainer;

        if (!desktopContainer) imageContainer = window.document.querySelector('#view-mobile');

        // Substract 15 of scroll width and 24 of padding
        const widthBase = desktopContainer ? desktopContainer.clientWidth - 15 - 24 : imageContainer.clientWidth;
        const imageHeight = widthBase * (A4_HEIGHT_MM / A4_WIDTH_MM);

        return { width: widthBase, height: imageHeight, fontSize: form?.Layout.FontSize, fontFamily: form?.Layout.FontFamily };
    }

    const areQuestionsAnswered = () => {
        let result = true;
        const isAdvancedForm = form.FormDisplay.toLowerCase() === "onpreview";
        formTransform.forEach((form, i) => {
          form.forEach((question, j) => {
            question.components = question.components.map((comp, k) => {
              if (comp.type === ATTACHMENT && comp.required.toUpperCase() === MANDATORY && !comp.response.fileinbase64) {
                result = false;
                comp.error = true;
              }
              else if (comp.type === CHECKBOX && comp.required.toUpperCase() === MANDATORY && !comp.response) {
                result = false;
                comp.error = true;
              }
              // check text is not empty
              else if (comp.type === TEXTBOX && !checkDisabled(comp)) {
                const responseText = isAdvancedForm ? (comp.response.text === "" || comp.response.text === null) : (comp.response === "" || comp.response === null);
                if ((comp.required.toUpperCase() === MANDATORY || !comp.optional) && responseText) {
                    result = false;
                    comp.error = true;
                }
              }
              // check radiobutton is selected
              else if (comp.type === RADIOBUTTON && !checkDisabled(comp) && (comp.selectedchoice === undefined || comp.selectedchoice === null) && comp.required.toUpperCase() === MANDATORY) {
                result = false;
                comp.error = true;
              } else {
                comp.error = false;
              }
              if (comp.error) {
                const clonedForm = Helper.clone(formTransform);
                clonedForm[i][j]["components"][k] = comp;
                setFormTransform(clonedForm);
              }
              return comp;
            });
          });
        });
        return result;
      }

    return (
        <FormContext.Provider value={{
            form,
            setForm,
            formTransform,
            setFormTransform,
            document,
            setDocument,
            isHTML,
            checkDisabled,
            cleanForm,
            getComponentOriginalPosition,
            getScreenDimensions,
            areQuestionsAnswered,
            maxPageWithQuestions,
            setMaxPageWithQuestions,
        }}>
            {children}
        </FormContext.Provider>
    );
}