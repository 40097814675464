import { LOCATION_REQUIRED } from "../constants/GeneralConstants";
import { privGetRequest, privGetRequestWithSignal, privPostRequest, privPutRequest } from "../vidCloud/priv";

const EmailSignatureService = {};

EmailSignatureService.sendOTPSms = (emailId, body) => {

  return privPutRequest(`/emailsignatures/${emailId}/otpsms`, body);
}

EmailSignatureService.sendOTPCode = (emailId, body) => {

  return privPutRequest(`/emailsignatures/${emailId}/otpcode`, body);
}

EmailSignatureService.resendOtpSms = (emailId, body) => {

  return privPutRequest(`/emailsignatures/${emailId}/resendotpsms`, body);
}

EmailSignatureService.otpCall = (emailId, body) => {

  return privPutRequest(`/emailsignatures/${emailId}/otpcall`, body);
}

EmailSignatureService.sendEvidence = (emailId, body) => {

  return privPostRequest(`/emailsignatures/${emailId}/evidence`, body);
}

EmailSignatureService.getQRData = (body) => {
  return privPostRequest(`/emailsignatures/qr`, body);
}

EmailSignatureService.pollingQR = (tokenId, signal) => {
  return privGetRequestWithSignal(`/emailsignatures/qr/${tokenId}/polling`, signal);
}

EmailSignatureService.sendQRGraphData = (tokenId, body) => {
  return privPutRequest(`/emailsignatures/qr/${tokenId}`, body);
}

EmailSignatureService.sendSign = (signerGUI, body) => {
  localStorage.removeItem(LOCATION_REQUIRED);

  return privPostRequest(`/emailsignatures/${signerGUI}`, body);
}

EmailSignatureService.signMultipleDocuments = (body) => {
  return privPostRequest(`/emailsignatures`, body);
}

EmailSignatureService.getSSIUrl = (signerGuid) => {
  return privGetRequest(`/emailsignatures/${signerGuid}/ssiurl`);
}

EmailSignatureService.sendSSICode = (signerGuid, body) => {
  return privPostRequest(`/emailsignatures/${signerGuid}/ssicode`, body);
}

EmailSignatureService.getAppearanceConfig = (emailId) => {
  return privGetRequest(`/emailsignatures/${emailId}/appearance`)
}

EmailSignatureService.getCriiptoUrl = (signerGuid) => {
  return privGetRequest(`/emailsignatures/${signerGuid}/criiptourl`);
}

EmailSignatureService.sendCriiptoCode = (emailId, body) => {
  return privPostRequest(`/emailsignatures/${emailId}/criiptocode`, body);
}

EmailSignatureService.getAOCValidUrl = (signerGuid) => {
  return privGetRequest(`/emailsignatures/${signerGuid}/aocvalidurl`);
}

EmailSignatureService.sendAOCValidCode = (emailId, body) => {
  return privPostRequest(`/emailsignatures/${emailId}/aocvalidcode`, body);
}

EmailSignatureService.sendSwisscomSignRequest = (signerGuid, body) => {
  return privPostRequest(`/emailsignatures/swisscom/${signerGuid}/signrequest`, body);
}

EmailSignatureService.completeSwisscomSignature = (signerGuid, requestID, body) => {
  return privPostRequest(`/emailsignatures/swisscom/${signerGuid}/completesignature/${requestID}`, body);
}

export default EmailSignatureService;