import { Modal } from "antd";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CheckIcon } from "../../SvgComponents/CheckIcon/CheckIcon";
import { CancelIcon } from "../../SvgComponents/CancelIcon/CancelIcon";

export const SMSConfirmationModal = ({ openModalPhoneNumberConfirmation, setOpenModalPhoneNumberConfirmation, handleShowCustomPhoneNumber, phoneNumber }) => {
    const { t } = useTranslation();

    return (
        <Modal
            title={t('general.confirm')}
            footer={null}
            open={openModalPhoneNumberConfirmation}
            closable={true}
            onCancel={() => setOpenModalPhoneNumberConfirmation(false)}
        >
            <div className="modal-container">
                <p className="nu-input-label">{t('sms.custom_phone_validation1_call')} <span className="phone-number-modal">({phoneNumber})</span>. {t('sms.custom_phone_validation2_call')}</p>
                <Row className="action-buttons mobile-button">
                    <Col md={6} xs={6}>
                        <button type="button" className="btn btn-secondary btn-cancel" onClick={() => setOpenModalPhoneNumberConfirmation(false)}>
                            <span className="cancel-text">{t('general.cancel')}</span>
                            <CancelIcon />
                        </button>
                    </Col>
                    <Col md={6} xs={6}>
                        <button type="button" className="btn btn-primary btn-active" onClick={handleShowCustomPhoneNumber}>
                            <span className="cancel-text">{t('general.confirm')}</span>
                            <CheckIcon />
                        </button>
                    </Col>
                </Row>
            </div>
        </Modal>
    );
};