import { detect } from 'detect-browser';
import { EVIDENCE_DATA, LOCATION_REQUIRED } from '../constants/GeneralConstants';
import IPService from '../services/IPService';

const getLocation = () => {
  return new Promise((resolve) => {
      if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition(
              (position) => {
                  const latitude = position.coords.latitude;
                  const longitude = position.coords.longitude;
                  resolve(`${latitude},${longitude}`);
              },
              () => {
                  // Error
                  resolve("");
              }
          );
      } else {
          // Not available
          resolve("");
      }
  });
}

export const getEvidenceData = async () => {
  let evidenceLocation = '';
  let browserStringified = '';
  let evidenceInfo = {};
  let clientIp = '';
  const locationRequired = localStorage.getItem(LOCATION_REQUIRED);

  try {
    let responseData = {};
    const savedEvidences = sessionStorage.getItem(EVIDENCE_DATA);
    if (savedEvidences) {
      responseData = JSON.parse(savedEvidences);
    } else {
      const browser = detect();
      responseData.browser = browser;
      const ipResponse = await IPService.getClientIP();
      responseData.ip = ipResponse.data.EvidenceIP;
      sessionStorage.setItem(EVIDENCE_DATA, JSON.stringify(responseData));
    }
    clientIp = responseData.ip;
    browserStringified = JSON.stringify(responseData.browser);
    if (locationRequired === "true") {
      evidenceLocation = await getLocation();
    }
  } catch (e) {
    console.log(e)
  }
  evidenceInfo = {
    IpAddress: clientIp,
    Location: evidenceLocation,
    From: browserStringified
  };

  return evidenceInfo;
};